
   .header-container{
    display: flex;
    justify-content: center;
    height: auto;
    position: relative;
    transition: all 0.3s ease-in-out; 
    background: transparent;
   }
   .header{
    position: fixed;
    display: flex;
    justify-content: center;
    top: 20px;
    height: auto;
    width: 1100px;
    background-color: rgba(246, 246, 246, 0.81);
    backdrop-filter: blur(8px) brightness(90%);
    transition: all 0.6s ease-in-out;
    z-index: 1001;
   }
   .header.scrolled {
    position: fixed;
    top: 0;
    width: 100%;
    /* height: 60px; */
  }
  .nav-bar-container{
    width: 1100px;
    display: flex;
    height: 100%;
  }
  .main-log-container{
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .main-logo-img{
    object-fit: contain;
    height: 80px;
    cursor: pointer;
  }
  .nav-bar-part-two{
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .top-bar{
    width: 100%;
    display: flex;
    background-color: var(--main-color);
    color: white;
    padding: 8px;
    justify-content: space-between;
  }
  .contact-info{
    width: auto;
    display: flex;
    gap: 30px;
    font-size: 10px;
  }
  .contact-info .material-icons {
    font-size: 10px;
  }
  .contact-info div{
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .contact-info a{
    color: white;
  }
  .social-media-links{
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 25px;
    margin-right: 10px;
  }
  .social-media-links svg{
    fill: #ffff;
    width: 12px;
    transition: transform 0.3s ease;
  }
  .social-media-links svg:hover{
    cursor: pointer;
    transform: scale(1.2);
    transition: fill 0.3s ease, transform 0.3s ease;
  }
  .social-media-links .material-icons{
    font-size: 15px;
  }
  .bottom-bar{
    height: 100%;
    display: flex;
    align-items: center;
    width: 100%;
    padding:20px;
    gap: 50px;
  }
  .nav-menu{
    display: flex;
    gap: 50px;
  }
  .contact-number-div{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    border-radius: 1px;
    border-left: 1.5px solid var(--gray);
  }

.customer-service-icon-container{
  margin-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: var(--main-color);
}
.contact-number{
  display: flex;
  flex-direction: column;
  font-size: 13px;
  color: var(--main-color);
}
.contact-heading{
  font-weight: 400;
}
.phone-number{
  font-weight: 700;
}
.phone-number a{
  color: var(--main-color);
  font-weight: 700;
  font-size: 13px;
}

/* header mobile */
.mobile-header-div{
  box-sizing: border-box;
  max-height: 80px;
  width: 100%;
  display: flex;
  padding: 0px 10px;
  justify-content: center;
  background-color: rgba(246, 246, 246, 0.81);
  backdrop-filter: blur(8px) brightness(90%);
  position: fixed;
  z-index: 1001;
}
.mobile-header-sub-div{
  width: 90%;
  display: flex;
  justify-content: space-between;
}
.header-logo-container{
  cursor: pointer;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header-logo-img{
  height: 60px;
}
.mobile-menu-button-div{
  height: auto;
  display: flex;
  align-items: center;
}

.menu-overlay{
  border-top: 1px solid var(--main-color);
  position: fixed;
  color: var(--main-color);
  width: 100%;
  height: calc(100dvh - 80px);
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(246, 246, 246, 0.81);
  backdrop-filter: blur(8px) brightness(90%);
  z-index: 1000;
  transform: translateY(-120%);
  transition: transform 0.5s ease;
  display: flex;
  flex-direction: column;
}
.menu-overlay.active {
  transform: translateY(0);
}
.mobile-contact-info{
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px;
}
.mobile-contact-info div a{
  font-weight: 550;
  font-size: 12px;
  color: var(--main-color);
}
.mobile-contact-info div{
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 550;
  font-size: 12px;
}
.mobile-contact-info .material-icons{
  font-size: 15px;
}
.contact-number-mobile-div{
  display: flex;
  gap: 10px;
}
.customer-service-icon-container-mobile{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: var(--main-color);
}
.customer-service-icon-container-mobile svg{
  fill: var(--main-color);
}
.contact-number-mobile{
  display: flex;
  flex-direction: column;
  gap: 0px;
}
.mobile-menu-items-container{
  width: 100%;
  display: flex;
  justify-content: center;
}
.mobile-menu-items-div{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100dvh - 120px);
  overflow: auto;
}
.mobileMenuButton{
  padding: 20px;
  cursor: pointer;
  font-size: 18px;
  color: var(--main-color);
  font-weight: 600;
  display: flex;
  align-items: center;
  transition: color 0.3s ease, font-weight 0.3s ease;
}
.mobileMenuButton.active,
.mobileMenuButton:hover{
  color: var(--main-color);
  font-weight: 800;
}

/* Style for the solutions menu container */
/* .solutions-menu-container {
  position: relative;
}

.solutions-submenu {
  position: absolute;
  top: 115%;
  left: auto;
  right: auto;
  display: block;
  opacity: 0;
  transform: translateY(-10px); 
  background-color: rgba(246, 246, 246, 0.81);
  backdrop-filter: blur(8px) brightness(99%);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1001;
  padding: 10px 0;
  width: 200px;
  border-radius: 4px;
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.solutions-menu-container:hover .solutions-submenu {
  opacity: 1;
  transform: translateY(0); 
  pointer-events: auto;
}

.submenu-item {
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  color: var(--main-color);
}

.submenu-item:hover {
  background-color: #f2f2f2;
} */
/* Container for the solutions menu */
.solution-header{
  display: flex;
  align-items: center;
  cursor: pointer;
}

.mobileMenuButton .material-icons , .solution-header .material-icons{
  font-size: 18px;
  color: var(--main-color);
}
.mobileMenuButton .material-icons.rotate ,.solution-header .material-icons.rotate{
  transform: rotate(180deg);
  transition: transform 0.3s ease;
}
.mobileMenuButton .material-icons.revert ,.solution-header .material-icons.revert{
  transform: rotate(0deg);
  transition: transform 0.3s ease;
}

/* .solutions-submenu {
  position: absolute;
  top: 115%;
  display: block;
  opacity: 0;
  left: -10px;
  transform: translateY(-10px);
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(8px) brightness(90%);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.12), 0 4px 6px rgba(0, 0, 0, 0.08);
  z-index: 1001;
  padding: 12px 0;
  border-radius: 8px;
  transition: opacity 0.3s ease, transform 0.3s ease;
  pointer-events: none;
}

.solutions-submenu::-webkit-scrollbar {
  width: 6px;
}

.solutions-submenu::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3); 
  border-radius: 4px;
}

.solutions-menu-container:hover .solutions-submenu {
  opacity: 1;
  transform: translateY(0);
  pointer-events: auto;
}

.submenu-item {
  display: flex;
  justify-content: space-between;
  padding: 12px 24px; 
  cursor: pointer;
  font-size: 15px;
  font-weight: 600;
  color: var(--main-color);
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease, color 0.3s ease;
  border-left: 4px solid transparent;
  white-space: nowrap;
}

.submenu-item:hover {
  background-color: rgba(245, 245, 245, 0.9); 
  border-left: 4px solid var(--main-color);
  box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.05);
} */
.submenu-item .material-icons.rotate{
  transform: rotate(180deg);
  transition: transform 0.3s ease;
}
.submenu-item .material-icons.revert{
  transform: rotate(0deg);
  transition: transform 0.3s ease;
}


.solutions-submenu .submenu-item {
  opacity: 0;
  transform: translateY(-5px);
  animation: fadeInUp 0.4s forwards;
  animation-delay: calc(0.1s * var(--index));
}

/* .mobile-sub-menu-container{
  max-width: 80vw;
  border: 1px solid var(--main-color);
  top: 50px;
  padding: 25px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-size: 16px;
  font-weight: 600;
} */
/* Mobile submenu container */
.solutions-sub-menu-container{
  /* position: relative; */
  display: flex;
  flex-direction: column;
}
.mobile-sub-menu-container {
  max-width: 85vw;
  width: fit-content;
  /* border: 2px solid var(--main-color); */
  /* top: 50px; */
  padding: 0px;
  display: flex;
  flex-direction: column;
  /* gap: 15px; */
  font-size: 16px;
  font-weight: 600;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); 
  transform: translateY(10px);
  opacity: 0;
  height: 0;
  visibility: hidden;
  overflow: hidden;
  transition: opacity 0.4s ease, transform 0.4s ease, height 0.4s ease, visibility 0s 0.4s;}

/* Active state to trigger visibility */
.mobile-sub-menu-container.active {
  margin-left: 10px;
  opacity: 1;
  height: auto;
  visibility: visible;
  padding: 20px;
  transform: translateY(0);
  transition: opacity 0.4s ease, transform 0.4s ease, height 0.4s ease, visibility 0s;
}

/* Styling for each submenu item */
.mobile-submenu-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  /* background-color: rgba(246, 246, 246, 0.85); */
  border-radius: 6px;
  transition: background-color 0.3s ease, transform 0.3s ease;
  cursor: pointer;
  animation: fadeInUp 0.4s forwards;
  animation-delay: calc(0.1s * var(--index));
}

.mobile-submenu-item:hover {
  background-color: #e9f1ff;
  transform: translateY(-2px); /* Lift effect */
}
.mobile-submenu-item .material-icons.rotate{
  transform: rotate(180deg);
  transition: transform 0.3s ease;
}
.mobile-submenu-item .material-icons.revert{
  transform: rotate(0deg);
  transition: transform 0.3s ease;
}
.solution-submenu-item-container {
  opacity: 0;
  visibility: hidden;
  height: 0;
  transform: translateY(-10px);
  padding-left: 15px;
  transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s ease, height 0.3s ease;
}

.solution-submenu-item-container.show {
  opacity: 1;
  visibility: visible;
  height: auto;
  transform: translateY(0);
}

.no-scroll {
  overflow: hidden;
}
@keyframes slideIn {
  from {
    transform: translateY(20px); 
  }
  to {
    transform: translateY(0);
  }
}
@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}


/* media */
@media screen and (max-width: 1100px){
  .header{
    width: 900px;
  }
  .header.scrolled{
    width: 100%;
  }
  .nav-bar-container{
    width: 900px;
  }
  .main-log-container{
    width: 150px;
  }
  .main-logo-img{
    height: 70px;
  }
  .bottom-bar{
    padding: 15px;
    gap: 0;
    justify-content: space-between;
  }
  .nav-menu{
    gap: 30px;
  }
  @media   screen and (max-width: 400px){
    .customer-service-icon-container-mobile{
      width: 25px;
      height: 25px;
    }
    .customer-service-icon-container-mobile svg{
      width: 18px;
    }
    .mobile-contact-info div{
      font-size: 11px;
    }
  }
}