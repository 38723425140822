.trusted-by-div{
    padding: 30px 0px;
    width: 100%;
    margin-bottom: 200px;
}
.trusted-by-div .container{
    display: flex;
    flex-direction: column;
    gap: 50px;
}
.companys-list{
  width: 100%;
  padding: 0px 120px;
  margin-top: 20px;
}
.company-scroll{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
  align-items: center;
  justify-content: center;
}
.company-scroll img{
    width: 170px;
    height: 90px;
    object-fit: contain;
    filter: grayscale(100%);
    transition: filter 0.3s ease;
    margin-right: 46px;
}
.company-scroll img:hover{
    cursor: pointer;
    filter: grayscale(0%);
}

/* @media screen and  (max-width: 1200px){
  .companys-list{
    overflow: hidden;
    -webkit-mask: linear-gradient(
      90deg,
      transparent,
      white 20%,
      white 80%,
      transparent
    );
    mask: linear-gradient(90deg, transparent, black 10%, black 95%, transparent);
  }
  .company-scroll{
    width: max-content;
    animation: scroll 30s forwards linear infinite
  }
} */
@media screen and (max-width:900px){
    .trusted-by-div{
        margin-bottom: 0px;
      }
}
@media screen and (max-width: 768px){
  .company-scroll img{
    width: 120px;

  }
}
@media screen and (max-width:600px) {
  .company-scroll{
    justify-content: center;
    gap: 20px;
  }
  .trusted-by-div .large-heading{
    text-align: center;
  }
}

@media screen and (max-width:410px){
  .company-scroll img{
    width: 100px;
    gap: 10px;
  }
}