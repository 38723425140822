
/* link bttn */
.linkBttn {
    position: relative;
    text-decoration: none;
    color: var(--main-color);
    cursor: pointer;
    font-size: 15px;
    font-weight: 600;
    display: inline-block;
  }
  
  .linkBttn::before {
    content: "";
    left: 0;
    bottom: -1px;
    width: 100%;
    height: 1px;
    position: absolute;
    background: var(--main-color);
    opacity: 0;
    transition: opacity 0.5s ease;
  }
  
  .linkBttn::after {
    content: "";
    left: 0;
    bottom: -2px;
    width: 0%;
    height: 2px;
    position: absolute;
    background: var(--main-color);
    transition: width 0.5s ease;
  }
  
  .linkBttn:hover::before,
  .linkBttn.active::before {
    opacity: 1;
  }
  
  .linkBttn:hover::after,
  .linkBttn.active::after {
    width: 100%;
  }
  
  .linkBttn.active {
    color: var(--main-color) !important;
  }

  /* card button */
  .card-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--main-color);
    border: 1px solid var(--main-color);
    color: #ffffff;
    border-radius: 5px;
    padding: 8px 16px;
    font-size: 12px; 
    font-weight: 500;
    gap: 5px;
    letter-spacing: 0.5px;
    transition: all 0.3s ease;
    cursor: pointer;
  }
  
  .card-button:hover {
    background-color: var(--main-color-dark);
    border-color: var(--main-color-dark);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  /* card button white */
  .card-button-white {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--light-gray);
    border: 1px solid var(--light-gray);
    color: var(--main-color);
    border-radius: 5px;
    padding: 8px 16px;
    font-size: 12px; 
    font-weight: 500;
    gap: 5px;
    letter-spacing: 0.5px;
    transition: letter-spacing 0.4s ease, 
                box-shadow 0.4s , 
                background-color 0.4s ease, 
                color 0.4s ease;    
    cursor: pointer;
  }
  
  .card-button-white:hover {
    background-color: var(--main-color);
    color: white;
    letter-spacing: 1px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }


  /* submit button */
  .submit-button {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
    border-radius: 5px;
    font-size: 18px;
    font-weight: bold;
    border: 1px solid white;
    padding: 6px 20px;
    color: white;
    background-color: var(--main-color);
    transition: letter-spacing 0.3s ease, background-color 0.3s ease
  }
  .submit-button:hover{
    letter-spacing: 1.5px;
  }
  .submit-button svg{
    width: 24px;
  }

  /* download  button */
  .download-button{
    cursor: pointer;
    background-color: var(--main-color);
    color: #ffffff;
    border-radius: 5px;
    padding: 8px 15px;
    font-size: 15px;
    font-weight: 400;
    display: flex;
    align-items: center;
    gap: 20px;
  }
  .download-button .material-icons{
    font-size: 20px;
  }
.download-button svg{
  width: 24px;
  fill: white;
}