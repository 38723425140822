.arrow-up-navigation{
    position: fixed;
    right: 25px;
    bottom: -45px;
    background-color: #003f87;
    color: white;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    /* opacity: 0; */
    /* visibility: hidden; */
    transition: bottom 0.3s ease-in-out, visibility 0.3s ease-in-out;
    z-index: 1000;
  }
  .arrow-up-navigation svg{
    fill: white;
    z-index: 1001;
    opacity: 1;
    display: block;
  }
  .arrow-up-navigation.show{
    bottom: 20px;
  }
  .arrow-up-navigation.hide{
    bottom: -45px;
  }
  .arrow-icon-container{
    border: 2px solid white;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    /* padding: 1px; */
  }