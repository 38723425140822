.corporate-video{
    position: relative;
    border-radius: 15px;
    display: flex;
    background-color: #0000;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.corporate-video img,.corporate-video video{
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 15px;
}
.paly-button-div{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    flex-direction: column;
    z-index: 1;
}
.play-button {
    background: rgba(112, 111, 111, 0.4);
    border: none;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    outline: none;
    transition: background-color 0.3s;
  }
  .play-button:hover {
    background: rgba(112, 111, 111, 0.9);
  }
  
  .play-button span {
    display: block;
    color: white;
    font-size: 24px;
    font-weight: bold;
  }

  .video-title {
    font-weight: 500; 
    color: var(--main-color);
    font-size: 16px;
    text-align: center;
  }

