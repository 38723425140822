/* .talk-section{
    max-width: 100%;
    position: relative;
  }
  .talk-section .container{
    max-width: 100vw;
    top: 50px;
    position: absolute;
    z-index: 99;
  }
  .talk-box{
      background-color: var(--main-color);
      padding: 40px 60px;
      padding-bottom: 80px;
      border-radius: 25px;

      width: 100%;
      transform: translateY(-50%);
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
  }
  .talk-box-input-section{
      width: 50%;
      display: flex;
      flex-direction: column;
  }
  .talk-box-input-section .fade-heading{
    font-size: 24px;
    font-weight: bold;
    color: whitesmoke;
  }
  .input-div{
    width: 100%;
    padding: 5px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  .submit-div{
    display: flex;
    align-items: end;
    justify-content: end;
    margin-top: 15px;
  }
  .contact-support-section{
    padding-top: 20px;
    width: 30%;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .contact-support-section .heading{
    font-size: 20px;
    font-weight: 600;
    color: white;
  }
  .contact-details-div{
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .contact-details-div .contact{
    display: flex;
    gap: 10px;
    color: white;
    font-size: 16px;
    font-weight: 500;
    line-height: 30px;
    padding-left: 5px;
  }
  .contact-details-div .contact svg{
    fill: white;
    width: 16.88px;
  }
  .subscribe-div{
    margin-top: 15px;
  }
  .subscribe-div .heading{
    font-size: 30px;
  }
  .subscribe-div input{
    color: rgb(211, 211, 211);
  }
  .subscribe-div input::placeholder{
    color: rgb(211, 211, 211);
  }


@media screen and  (max-width: 1200px){
    .contact-support-section{
      width: 35%;
    }
}
@media screen and  (max-width: 1100px){
    .contact-support-section{
      width: 40%;
    }
  }
  @media screen and (max-width:900px){
    .talk-section .container{
        position: relative;
        top:0px;
      }
    .talk-box{
        transform: translateY(0);
        flex-direction: column;
        gap: 50px;
        margin-bottom: 20px;
    }
    .talk-box-input-section{
        width: 100%;
    }
    .contact-support-section{
        width: 100%;
    }
  }
  @media screen and (max-width:400px){
    .talk-box{
      padding: 20px 30px;
    } 
  } */


  .talk-section{
    max-width: 100%;
    position: relative;
  }
  .talk-section .container{
    max-width: 100vw;
    top: 50px;
    position: absolute;
    z-index: 99;
  }
  .talk-box{
      background-color: var(--main-color);
      padding: 40px 60px;
      padding-bottom: 80px;
      border-radius: 25px;
      width: 100%;
      transform: translateY(-50%);
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
  }
  .talk-box-first-part{
      width: 30%;
      display: flex;
      flex-direction: column;
  }
  .talk-box-first-part .fade-heading{
    font-size: 24px;
    font-weight: bold;
    color: whitesmoke;
  }
  .talk-box-second-part{
    padding-top: 20px;
    width: 30%;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .talk-box-second-item{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
  }
  .talk-box-second-item svg{
    fill: white;
    width: 18px;
  }
  .talk-box-second-item .item-location{
    font-size: 15px;
    font-weight: 600;
    color: white;
  }
  .talk-box-second-item span{
    font-size: 12px;
    font-weight: 500;
    color: whitesmoke;
  }
  .contact-support-section{
    padding-top: 20px;
    width: 30%;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .contact-support-section .heading{
    font-size: 20px;
    font-weight: 600;
    color: white;
  }
  .contact-details-div{
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .contact-details-div .contact{
    display: flex;
    gap: 10px;
    color: white;
    font-size: 16px;
    font-weight: 500;
    line-height: 30px;
    padding-left: 5px;
  }
  .contact-details-div .contact svg{
    fill: white;
    width: 16.88px;
  }
  .subscribe-div{
    margin-top: 15px;
  }
  .subscribe-div .heading{
    font-size: 30px;
  }
  .subscribe-div input{
    color: rgb(211, 211, 211);
  }
  .subscribe-div input::placeholder{
    color: rgb(211, 211, 211);
  }


@media screen and  (max-width: 1200px){
    .talk-box{
      justify-content: flex-start;
      gap: 20px;
    }
    .talk-box-first-part{
      width: 90%;
    }
    .talk-box-second-part{
      width: 50%;
    }
    .contact-support-section{
      width: 40%;
    }

}
@media screen and (max-width:900px){
  .talk-section .container{
      position: relative;
      top:0px;
    }
  .talk-box{
      transform: translateY(0);
  }
}
@media screen and (max-width:700px){
    /* .talk-box-first-part h3{
      font-size: 25px;
      text-align: start;
    } */
    .talk-box-input-section{
        width: 100%;
    }
    .talk-box-second-part{
      width: 100%;
    }
    .contact-support-section{
        width: 100%;
    }
  }
  @media screen and (max-width:400px){
    .talk-box{
      padding: 20px 30px;
    } 
  }