@import '/src/assets/css/footer.css';
@import '/src/assets/css/header.css';
@import '/src/assets/css/Common/buttons.css';
@import  '/src/assets/css/Common/common.css';
@import '/src/assets/css/mediaQuery\'s.css';
@import url('/src/assets/css/Common/variables.css');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
@import url('./assets/fonts/fonts.css');
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Gilroy', sans-serif;
}
html {
  scroll-behavior: smooth;
}
