.reference-first-section{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60dvh;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.reference-first-section .large-heading{
  color: white;
  margin-top: 100px;
}
.refrence-year-section{
  color: var(--main-color);
  width: 100%;
  display: flex;
  gap: 20px;
  margin-top: 50px;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
.refrence-year-section .refrence-year-title{
  font-size: 22px;
  font-weight: 700;
}

.refrence-years-div{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  font-size: 40px;
  font-weight: 700;
}
.refrence-year-item{
  cursor: pointer;
  transition: color 0.3s ease-in-out , transform 0.3s ease-in-out;
}
.refrence-year-item:hover{
  color: #333;
  transform: scale(1.1);
}
.refrence-card-container{
  margin: 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 40px;
  padding: 10px;
  border-radius: 10px;
}
.refrence-card{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 50px;
  padding: 30px;
  background-color: whitesmoke;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  background: linear-gradient(135deg, #f5f5f5, #e0e0e0);

}

.refrence-card-heading-div{
 width: 90%;
 display: flex;
 align-items: center;
 justify-content: flex-start;
 font-size: 25px;
 font-weight: 700;
 gap: 10px;
}
.refrence-year-heading{
  white-space: nowrap;
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--main-color);
}
.refrence-card-heading-div span{
  font-size: 25px;
}
.refrence-project-name{
  text-align: center;
}
.card-content-first-section{
  width: 100%;
  display: flex;
  justify-content: center;
  /* flex-wrap: wrap; */
  /* align-items: center; */
  gap: 40px;
}
.first-content-item-container{
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  padding: 7px;
  width: 200px;
  text-align: center;
}
.first-content-item-container .material-icons{
  font-size: 28px;
  color: var(--main-color);
}
.first-content-item-container .item-text{
  font-size: 12px;
  font-weight: 500;
}
.first-content-item-container .content-text{
  font-size: 15px;
  font-weight: 700;
}
.supply-section {
  width: 100%;
  padding: 10px;
  margin-top: 20px;
  background-color: #f9f9f9;
  border-left: 4px solid var(--main-color);
  border-radius: 5px;
}

.supply-title {
  font-size: 16px;
  font-weight: 700;
  color: var(--main-color);
  margin-bottom: 5px;
}

.supply-text {
  font-size: 16px;
  font-weight: 400;
  color: #333;
  line-height: 1.5;
}


.year-filter-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  gap: 10px;
}
/* .reference-note {
  width: 60%;
  margin: 20px auto;
  padding: 6px;
  background: linear-gradient(135deg, #ffefba, #ffffff);
  border-left: 4px solid var(--main-color);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.note-text {
  font-size: 14px;
  font-weight: 500;
  color: #333;
  line-height: 1.5;
  text-align: center;
} */
.reference-note {
  font-size: 16px;
  color: var(--main-color);
  font-weight: 500;
  padding: 20px;
}


.year-button {
  padding: 10px 15px;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 25px;
  background: var(--main-color);
  color: white;
  cursor: pointer;
  transition: transform 0.2s, background 0.3s, color 0.3s;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.year-button:hover {
  background: #1e3071;
  transform: scale(1.05);
}

.year-button.selected {
  background: white; 
  color: var(--main-color);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.dash {
  color: var(--main-color); 
  font-size: 18px;
  font-weight: bold;
}

/* Shimmer Effect */
.shimmer-div {
  width: 100%;
  height: 20px;
  background: linear-gradient(
    to right,
    #eeeeee 8%,
    #dddddd 18%,
    #eeeeee 33%
  );
  background-size: 1200px 100%;
  animation: shimmer 1.5s infinite;
  border-radius: 4px;
}

/* For varied shimmer size in different areas */
.shimmer-div.small {
  height: 12px;
  width: 50%;
}
.shimmer-div.medium {
  height: 16px;
  width: 75%;
}
.shimmer-div.large {
  height: 20px;
  width: 100%;
}
.shimmer-div.content-text{
  width: 60px;
  height: 16px;
}

@keyframes shimmer {
  0% {
    background-position: -1200px 0;
  }
  100% {
    background-position: 1200px 0;
  }
}


@media screen and (max-width: 971px) {
  .refrence-year-heading {
      font-size: 25px;
  }
  .card-content-first-section{
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 768px) {
  .reference-first-section {
      height: 50dvh;
  }
  .large-heading {
      font-size: 28px;
      margin-top: 50px;
      text-align: center;
  }
  .refrence-years-div {
      font-size: 16px;
      gap: 10px;
  }
  .refrence-card {
      padding: 20px;
      gap: 30px;
  }
  .refrence-card-heading-div {
      flex-direction: column;
      align-items: center;
      gap: 5px;
      font-size: 20px;
  }
  .refrence-card-heading-div span{
      display: none;
  }
  .card-content-first-section{
    gap: 15px;
    /* justify-content: space-between; */
  }
  .first-content-item-container{
    width: 180px;

  }
}

@media screen and (max-width: 480px) {
  .refrence-year-heading {
      font-size: 18px;
  }
  .refrence-years-div {
      font-size: 14px;
      gap: 8px;
  }
  .refrence-card-heading-div {
      font-size: 18px;
  }
  .refrence-year-section{
      flex-direction: column;
  }
}

@media screen and (max-width:451px){
  .first-content-item-container{
    width: 130px;
  }
  .first-content-item-container .material-icons{
    font-size: 23px;
  }
  .first-content-item-container .content-text{
    font-size: 12px;
  }
}
/* @media screen and (max-width:391px){
  .first-content-item-container{
    width: 150px;
  }
  .first-content-item-container .material-icons{
    font-size: 23px;
  }
  .first-content-item-container .content-text{
    font-size: 12px;
  }
} */


