@import 'react-quill/dist/quill.snow.css';

.product-solution-first-div{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60dvh;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.product-solution-first-div .large-heading{
    margin-top: 100px;
    text-transform: uppercase;
}
.product-and-solutions-content{
    padding-bottom: 50px;
}
.product-and-solutions-content .small-text , .product-and-solutions-content p{
    margin-top: 30px;
}
.product-and-solutions-content{
  margin-top: 50px;
}
.product-and-solutions-content h1{
    margin-bottom: 20px;
}
.product-and-solution-sub-content{
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.product-and-solution-sub-content .content-heading{
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 10px;
    margin-top: 10px;
}
.product-and-solution-sub-content .small-text , .product-and-solution-sub-content p{
    margin-bottom: 20px;
}
.product-and-solution-sub-content .image-container{
    width: 100%;
    display: flex;
    justify-content: center;
}
.image-container img{
    margin-top: 25px;
    width: 50%;
    height: 100%;
    object-fit: contain;
    border-radius: 15px;
}
.product-and-solution-sub-content ul{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.product-and-solution-sub-content li{
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.product-and-solution-sub-content li .small-text , .product-and-solution-sub-content li p{
    margin-top: 0px;
    margin-bottom: 0px;
}
.point-header{
    font-size: 14px;
    font-weight: 600;
    word-break: keep-all;
    word-wrap: nowrap;
}
/* .products-section{
    padding: 10px 60px;
} */
/* .product-item{
    display: flex;
    flex-direction: column;
    gap: 40px;
}
.sub-heading{
    font-weight: 600;
    font-size: 16px;
} */
.products-section{
    padding: 10px 60px;
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
}
.product-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    padding: 16px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    max-width: 250px;
    transition: transform 0.3s ease;
  }
  
  .product-item:hover {
    transform: translateY(-5px);
  }
  
  .sub-heading {
    font-size: 17px;
    font-weight: 600;
    margin-bottom: 10px;
    color: #333;
    text-align: center;
  }
  
  .product-image-container {
    width: 100%;
    height: auto;
    max-height: 180px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .product-image-container img {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 4px;
  }

  .solution-content strong{
    font-weight: 700;
    color: black;
  }
  .solution-content .margin-left-25{
    margin-left: 25px;
  }
  .solution-content .margin-bottom-20{
    margin-bottom: 20px;
  }
  .solution-content .margin-bottom-10{
    margin-bottom: 10px;
  }
  .solution-content li ul{
    margin-bottom: 5px;
  }
  .solution-content p{
    margin-top: 0px;
    margin-bottom: 30px;
    overflow-wrap: break-word;
    hyphens: auto;              
    word-break: keep-all;
    text-align: justify;
  }
  .solution-content h2{
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .solution-content ul{
    display: flex;
    flex-direction: column;
    gap: 25px;
    padding-left: 25px;
    margin-bottom: 40px;
  }
  /* .solution-content ul strong{
    display: block;
  } */
  .solution-content ul li{
    font-size: 16px;
    font-weight: 500;
    line-height: 32px;
    color: #3D3D3D;
  }
  .solution-content h3{
    color: var(--main-color);
    margin-bottom: 20px;
  }
  a{
    text-decoration: none;
  }
 li img , p img{
    /* display: block; */
    object-fit: contain;
    max-width: 50dvw;
    border-radius: 10px;
    margin: auto;
  }
  .download-button{
    gap: 6px;
  }
  .download-button-group{
    display:flex;
    gap:20px;
  }
  .download-button-group .download-button-container{
    width: auto;
  }
  .solutions-content-card-container{
    width: 100%;
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
    margin-bottom: 50px;
  }


  .solution-content-card {
    cursor: pointer;
    background-color: #ffffff;
    padding: 25px;
    border-radius: 15px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
    width: calc((100% - 90px) / 3);
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease , background-color 0.3s ease , color 0.3s ease;
  }
  
  .solution-content-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
    background-color: var(--main-color);
    color: white;
  }
  .solution-content-card .card-heading{
    font-size: 24px;
    font-weight: 700;

  }
  .solution-content-card .card-content{
    font-size: 14px;
    font-family: sans-serif;
    overflow-wrap: break-word;
    hyphens: auto;              
    word-break: keep-all;
    text-align: justify;
  }
  /* .image-container{
    width: 50%;
    margin: auto;
    padding-bottom: 50px;
  }
  .image-container img{
    width: 100%;
    object-fit: contain;
  } */
   @media screen and (max-width:1200px){
    .solution-content-card{
      width: calc(50% - 15px);
    }
   }
  @media screen and (max-width:1000px){
    li img ,p img{
      max-width: 80dvw;
    }
  }
  /* Media query for responsiveness */
  @media (max-width: 768px) {
    .product-item {
      max-width: 100%;
    }
  }
  @media  screen and (max-width:700px) {
    .product-and-solutions-content{
        padding-bottom: 20px;
    }
    .solutions-content-card-container{
      justify-content: center;
      padding: 0px 55px;
    }
    .solution-content-card{
      width: calc(100% - 20px);
    }
  }
@media screen and (max-width:465px){
  .download-button-group{
    flex-direction: column;
    align-items: flex-start;
  }
  .solutions-content-card-container{
    padding: 0px;
  }
}
  @media screen and (max-width: 400px) {
    .solution-content h3 , .solution-content h2{
      text-align: center;
      margin: 10px;
    }
    .solution-content ul , .solution-content p{
      line-break: anywhere;
      padding: 0px;
    }
  }
  