@import '../../fonts/fonts.css';
.paragraph{
  overflow-wrap: break-word;
  hyphens: auto;              
  word-break: keep-all;
  text-align: justify;
  margin-bottom: 10px;
}
.large-heading{
    font-family: 'Gilory',sans-serif;
    font-size: 60px;
    font-weight: bold;
    color: var(--dark-text-color);
}
.heading-div{
    width: 100%;
    display: flex;
    justify-content: center;
}
.small-heading ,h3{
    font-size: 1.2rem;
    font-weight: bold;
    color: #3D3D3D;
}

.small-text , p{
    font-size: 16px;
    font-weight: 500;
    line-height: 32px;
    color: #3D3D3D;
}
.sub-large-heading , h2{
    font-size: 1.5rem;
    font-weight: 600;
}
.medium-text{
    font-family: 'Gilory',sans-serif;
    font-weight: normal;
    font-size: 24px;
}
.medium-heading{
    font-size: 22px;
}

.description{
    width: 50%;
    margin-top: 10px;
    color: #3D3D3D;
}


.words-letter-capital{
    text-transform: capitalize;
}
.button-group{
    width: 100%;
    display: flex;
    align-items: center;
    gap: 20px;
}

.wf-100{
    width: calc(100% - 10px);
  }
  .wf-50{
    width: calc(50% - 10px);
  }
/* input */
.error-message {
    color: #ff4d4f;
    font-size: 0.875rem;
    margin-top: 0.25rem;
    display: block;
    font-weight: 500;
    padding-left: 0.5rem;
    position: relative;
    animation: fadeIn 0.3s ease-in-out;
    line-height: 1.2;
}
textarea,input{
    width: 100%;
    background-color: var(--main-color);
    color: white;
    outline: none;
    border: none;
    border-bottom: 1px solid white;
    padding: 15px 10px;
    font-size: 16px;
    font-weight: 500;
    font-weight: lighter;
  }
  textarea::placeholder,input::placeholder{
    color: white;
    font-size: 16px;
    font-weight: 500;
    font-weight: lighter;
  }
  textarea::-webkit-scrollbar {
    width: 6px;
  }

  textarea::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 3px;
  }

  textarea::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 3px;
  }

  textarea::-webkit-scrollbar-thumb:hover {
    background: #525252;
  }

  #google-map:hover::before, #google-map:focus::before {
    content: none !important;
  }
  #google-map:focus::after{
    content: none !important;
  }

    /* email/subscribe */
    .email-input {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        border-bottom: 1px solid white;
        margin-left: 5px;
        max-width: 300px;
    }
    
    .email-input input {
        width: calc(100% - 20px);
        border-bottom: none;
        padding: 12px 8px;
    }
    .email-input svg{
        cursor: pointer;
        width: 15px;
        fill: white;
    }
    
    @keyframes subtle-shake {
        0%, 100% {
          transform: translateX(0);
        }
        25% {
          transform: translateX(-2px);
        }
        50% {
          transform: translateX(2px);
        }
        75% {
          transform: translateX(-2px);
        }
      }

      @keyframes contentFadeIn {
        0% {
            transform: translateY(20px);
            opacity: 0;
        }
        100% {
            transform: translateY(0);
            opacity: 1;
        }
    }
    
    .aos-init {
        opacity: 0; /* Initial state */
    }
    
    .aos-animate {
        animation: contentFadeIn 2s forwards; /* Animation effect */
        opacity: 1; /* Ensure it's fully visible after animation */
    }
/* media */
@media screen and (max-width:1200px){
  .large-heading{
    font-size: 50px;
  }
  .medium-text{
    font-size: 22px;
  }
  .sub-large-heading , h2{
      font-size: 1.1rem;
  }
  .small-text , p{
    font-size: 14px;
  }
}
@media screen and (max-width: 1100px){

}
@media screen and (max-width: 1000px){
  .large-heading{
    font-size: 40px;
  }
  .medium-text{
    font-size: 20px;
  }
  .sub-large-heading , h2{
      font-size: 1rem;
  }
  .small-text , p{
    font-size: 14px;
  }
}
@media screen and (max-width: 825px){
  .large-heading{
    margin-top: 10px;
    font-size: 35px;
  }
  .medium-text{
    font-size: 17px;
  }
  .sub-large-heading , h2{
      font-size: 1rem;
  }
  .medium-heading{
    font-size: 20px;
  }
  .small-text , p{
    font-size: 14px;
  }
}
@media screen and (max-width:630px){
  .wf-50{
    width: 100%;
  }
  .wf-100{
    width: 100%;
  }
}
@media screen and (max-width:450px){
  .large-heading{
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 28px;
  }
}
@media screen and (max-width:390px){
  .large-heading{
    font-size: 25px;
  }
}