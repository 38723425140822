/* .solutions-submenu {
  position: absolute;
  top: 115%;
  display: block;
  opacity: 0;
  left: -10px;
  transform: translateY(-10px);
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(8px) brightness(90%);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.12), 0 4px 6px rgba(0, 0, 0, 0.08);
  z-index: 1001;
  padding: 12px 0;
  border-radius: 8px;
  transition: opacity 0.3s ease, transform 0.3s ease;
  pointer-events: none;
}

.solutions-submenu::-webkit-scrollbar {
  width: 6px;
}

.solutions-submenu::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3); 
  border-radius: 4px;
}

.solutions-menu-container:hover .solutions-submenu {
  opacity: 1;
  transform: translateY(0);
  pointer-events: auto;
}

.submenu-item {
  display: flex;
  justify-content: space-between;
  padding: 12px 24px; 
  cursor: pointer;
  font-size: 15px;
  font-weight: 600;
  color: var(--main-color);
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease, color 0.3s ease;
  border-left: 4px solid transparent;
  white-space: nowrap;
}

.submenu-item:hover {
  background-color: rgba(245, 245, 245, 0.9); 
  border-left: 4px solid var(--main-color);
  box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.05);
}
.fire-detection-sub-menu{
    padding-left: 20px;
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.3s ease, opacity 0.3s ease;
    opacity: 0;
  }
  .fire-detection-sub-menu.open{
    max-height: 500px;
    opacity: 1;
  }
  .fire-detection-sub-menu.close{
    max-height: 0;
    opacity: 0;
  }
  .solutions-submenu .submemu-item-div{
    position: relative;
  }
  .solutions-submenu .submemu-item-div:hover .material-icons{
    transform: rotate(180deg);
    transition: transform 0.3s ease;
  }

  .submenu-container{
    position: absolute;
    opacity: 0;
    top: 0;
    left: 100%;
    transform: translateX(-10px);
    background-color: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(8px) brightness(90%);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.12), 0 4px 6px rgba(0, 0, 0, 0.08);
    z-index: 1001;
    padding: 12px 0;
    border-radius: 8px;
    transition: opacity 0.3s ease, transform 0.3s ease;
    pointer-events: none;
    max-height: 350px;
    overflow-y: auto;
  }
  .submenu-container::-webkit-scrollbar {
    width: 6px;
}

.submenu-container::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 3px;
}

.submenu-container::-webkit-scrollbar-thumb {
    background: var(--main-color);
    border-radius: 3px;
}

.submenu-container::-webkit-scrollbar-thumb:hover {
    background: #525252;
}



  .solutions-submenu .submemu-item-div:hover .submenu-container{
    opacity: 1;
    transform: translateX(0);
    pointer-events: auto;
  } */

/* 
.solutions-submenu {
  position: absolute;
  top: 115%;
  display: block;
  opacity: 0;
  left: 50%;
  transform: translateX(-50%) translateY(-10px);
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(8px) brightness(90%);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.12), 0 4px 6px rgba(0, 0, 0, 0.08);
  z-index: 1001;
  padding: 12px 0;
  border-radius: 8px;
  transition: opacity 0.3s ease, transform 0.3s ease;
  pointer-events: none;
  display: flex;
  max-width: calc(100dvw - 40px);
}

.solutions-submenu::-webkit-scrollbar {
  width: 6px;
}

.solutions-submenu::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3); 
  border-radius: 4px;
}

.solutions-menu-container:hover .solutions-submenu {
  opacity: 1;
  transform: translateX(-50%) translateY(0);
  pointer-events: auto;
}

.submenu-item {
  display: flex;
  justify-content: space-between;
  padding: 12px 24px; 
  cursor: pointer;
  font-size: 15px;
  font-weight: 600;
  color: var(--main-color);
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease, color 0.3s ease;
  border-left: 4px solid transparent;
  white-space: nowrap;
}

.submenu-item:hover {
  background-color: rgba(245, 245, 245, 0.9); 
  border-top: 2px solid var(--main-color);
  box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.05);
}
.fire-detection-sub-menu{
    padding-left: 20px;
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.3s ease, opacity 0.3s ease;
    opacity: 0;
  }
  .fire-detection-sub-menu.open{
    max-height: 500px;
    opacity: 1;
  }
  .fire-detection-sub-menu.close{
    max-height: 0;
    opacity: 0;
  }
  .solutions-submenu .submemu-item-div{
    position: relative;
  }
  .solutions-submenu .submemu-item-div:hover .material-icons{
    transform: rotate(180deg);
    transition: transform 0.3s ease;
  }

  .submenu-container{
    position: absolute;
    opacity: 0;
    top: 100%;
    left: 50%;
    transform: translateX(-50%) translateY(-10px);
    background-color: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(8px) brightness(90%);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.12), 0 4px 6px rgba(0, 0, 0, 0.08);
    z-index: 1001;
    padding: 12px 0;
    border-radius: 8px;
    transition: opacity 0.3s ease, transform 0.3s ease;
    pointer-events: none;
    max-height: 350px;
    max-width: 50dvw;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

  }
  .submenu-container::-webkit-scrollbar {
    width: 6px;
}

.submenu-container::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 3px;
}

.submenu-container::-webkit-scrollbar-thumb {
    background: var(--main-color);
    border-radius: 3px;
}

.submenu-container::-webkit-scrollbar-thumb:hover {
    background: #525252;
}



  .solutions-submenu .submemu-item-div:hover .submenu-container{
    opacity: 1;
    transform: translateX(-50%) translateY(0);
    pointer-events: auto;
  } 
  
  .submenu-container .submemu-item:hover{
    background-color: var(--main-color);
    color: white;
  } */

  /* .solutions-submenu {
    position: fixed;
    display: flex;
    background-color: rgba(246, 246, 246, 0.81);
    backdrop-filter: blur(8px) brightness(90%);
    transition: all 0.6s ease-in-out;
    z-index: 1001;
    left: 0;
    right: 0;
    top: 106px;
  } */

  /* .solutions-submenu {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: rgba(246, 246, 246, 0.95);
    backdrop-filter: blur(8px);
    display: flex;
    padding: 20px;
    z-index: 1000;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
  
  .submenu-column {
    flex: 1;
    margin-right: 20px;
  }
  
  .submenu-heading {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #333;
  }
  
  .submenu-item {
    font-size: 14px;
    margin-bottom: 8px;
    color: #555;
    cursor: pointer;
    transition: color 0.3s ease;
  }
  
  .submenu-item:hover {
    color: var(--main-color);
  }
  
  .submenu-item span {
    font-weight: bold;
    margin-bottom: 5px;
    display: block;
  }
  
  .submenu-item div {
    padding-left: 20px;
    margin-top: 5px;
    font-size: 13px;
    color: #666;
  }
  
  .submenu-item div:hover {
    color: var(--main-color);
  }
  
  .solutions-submenu .submenu-column:last-child {
    margin-right: 0;
  } */
  .solution-submenu-overlay{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1002;
  }
  .solutions-submenu {
    position: fixed;
    top: 106px;
    left: 0;
    right: 0;
    background-color: rgba(246, 246, 246, 0.81);
    backdrop-filter: blur(8px) brightness(90%);
    display: flex;
    justify-content: center;
    gap: 50px;
    padding: 20px 50px;
    z-index: 1003;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.1);
    flex-wrap: wrap;
    transform: translateY(-10%);
    opacity: 0;
    visibility: hidden;
    transition: transform 0.4s ease-in-out, opacity 0.4s ease-in-out, visibility 0s 0.4s;
}

.solutions-submenu.active {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
  transition: transform 0.4s ease, opacity 0.4s ease;
}

.submenu-column {
    /* flex: 1; */
    margin-right: 20px;
    min-width: 250px;
}

.submenu-column:last-child {
    margin-right: 0;
}

.submenu-row {
    display: flex;
    flex-wrap: wrap;
    gap: 35px;
}

.submenu-heading {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #333;
}

.submenu-item {
    font-size: 14px;
    margin-bottom: 8px;
    color: #555;
    cursor: pointer;
    transition: color 0.3s ease;
    font-weight: 400;
    white-space: nowrap;
    transition: color 0.3s ease, font-weight 0.3s ease, border-bottom 0.3s ease;
}

.submenu-item:hover {
    color: var(--main-color);
    text-decoration: underline;
    font-weight: 700;
}

.submenu-item span {
    /* font-weight: bold; */
    margin-bottom: 5px;
    display: block;
}

.submenu-item div {
    padding-left: 20px;
    /* margin-top: 5px;
    font-size: 13px;
    color: #666; */
}

.submenu-item div:hover {
    color: var(--main-color);
}

.submenu-row .submenu-column {
    flex: 1;
    min-width: 200px; /* Adjust this as needed for layout */
}

.solutions-submenu .submenu-column:last-child {
    margin-right: 0;
}
.faded-heading-submenu{
  font-size: 12px;
  color: #333;
  font-weight: 700;
  margin-top: 20px;
  margin-bottom: 10px;
}

@media screen and (max-width:1337px){
  .solutions-submenu{
    gap: 0px;
    padding: 0px;
  }
}
@media screen and (max-width:1100px){
  .solutions-submenu{
    top: 97px;
  }
}
