.about-first-section{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60dvh;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.about-first-section .large-heading{
    color: white;
    margin-top: 100px;
}
.corporate-vedio-section{
    background-color: var(--light-gray);
}
.corporate-vedio-container{
    padding: 50px;
    display: flex;
    flex-direction: column;
    gap: 25px;
}
.corporate-vedio-container .small-text{
    line-height: 25px;
}
.about-second-section{
    width: 100%;
    min-height: 500px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    gap: 60px;
    padding-top: 80px;
    position: relative;
}
.about-second-section .arcLeftSmall{
    position: absolute;
    bottom: 0;
    z-index: 1;
    width: 200px;
}
.about-second-section .arcLeftBig{
    position: absolute;
    bottom: 0;
    z-index: 1;
    width: 400px;
}
.about-second-section .container{
    z-index: 2;
}
.about-second-image-div{
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}
.about-second-image-div img{
    width: 100%;
    height: auto;
    object-fit: contain;
}
.about-second-content{
    width: 100%;
}
.about-second-content-section-1-container{
    width: 85%;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    gap: 40px;
}
.section-1-item{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.section-1-item-content{
    width: calc(100% - 100px);
}
.section-1-item-content .heading{
    font-weight: bold;
    font-size: 22px;
}
.section-1-item-content .small-text{
    line-height: 25px;
}
.section-1-item-image{
    padding: 15px;
    width: 75px;
    height: 75px;
    border-radius: 10px;
    background-color: var(--main-color);
}
.section-1-item-image img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.about-second-content-section-2-container{
    margin-top: 35px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.about-second-content-section-2-container .heading{
    font-size: 50px;
    font-weight: bold;
}
.our-core-value-container{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
}
.our-core-value-item{
    width: calc((90% - 30px ) /  3);
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.our-core-value-heading-div{
    display: flex;
    gap: 8px;
    align-items: center;
}
.our-core-image-container{
    width: 35px;
    height: 35px;
    border-radius: 10px;
    padding: 7px;
    display: flex;
    align-items: center;
    background-color: var(--main-color);
}
.our-core-image-container img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.our-core-value-heading-div .heading{
    font-size: 22px;
    font-weight: bold;
}
.our-core-calue-content .small-text{
    line-height: 25px;
}
.download-button-container{
    width: 100%;
    padding-bottom: 50px;
}


@media screen and (max-width:1200px){
    .about-second-section{
        gap: 20px;
    }
    .about-second-content-section-1-container{
        width: 100%;
        gap: 30px;
    }
    .section-1-item-content{
        padding-right: 30px;
        width: calc(100% - 80px);      
    }
    .section-1-item-image{
        padding: 10px;
        width: 60px;
        height: 60px;
    }
    .about-second-content-section-2-container .heading{
        font-size: 30px;
    }
    .our-core-value-item{
        width: calc((100% - 65px ) /  3);
    }
    .our-core-value-heading-div .heading{
        font-size: 18px;
    }
    .our-core-calue-content .small-text{
        line-height: 20px;
    }
}
@media screen and (max-width: 1100px){
    .about-second-section{
        padding-top: 40px;
    }
    .about-second-content-section-2-container{
        margin-top: 25px;
    }
}
@media screen and (max-width:900px){
    .download-button-container{
        padding-bottom: 0px;
    }
    .about-second-section{
        gap: 0px;
        min-height: auto;
        /* justify-content: center; */
        padding-top: 0px;
    }
    .about-second-image-div{
        display: none;
        width: 0%;
    }
    .about-second-content{
        width: 100%;
        padding: 50px;
    }
    .about-second-content-section-1-container{
        align-items: flex-start;
    }
}
@media screen and (max-width:800px){
    .about-second-content{
        width: 100%;
        padding: 25px;
    }
    .our-core-value-container{
        flex-direction: column;
    }
    .our-core-value-item{
        width: 100%;
    }
}
@media screen and (max-width:550px){
    .corporate-vedio-container{
        padding: 10px;
    }
    .corporate-video{
        margin-top: 10px;
    }
}

@media screen and (max-width:420px){
    .corporate-vedio-container{
        padding: 0px;
    }
}