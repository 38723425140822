.who-we-are-section{
    background-color: var(--main-color);
    padding: 25px 0px;
    position: relative;
}
.arc-right-big{
    position: absolute;
    z-index: 0;
    width: 450px;
    bottom: 0;
    right: 0;
}
.arc-right-small{
    position: absolute;
    z-index: 0;
    width: 250px;
    bottom: 0;
    right: 0;
}
.who-we-are-section .container{
    display: flex;
    justify-content: space-between;
}
.who-we-are-part-one{
    /* width: calc(50% - 30px); */
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    z-index: 10;
}

.who-we-are-part-two{
    width: calc(50% - 30px);
    display: flex;
    align-items: center;
    justify-content: center;
}
.who-we-are-part-two .corporate-video{
    width: 100%;
}
.our-solution-section{
    background-color: var(--light-gray);
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    gap: 30px;
}
.our-solution-section .container{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.our-solution-section .container .small-text ,.our-solution-section .container p{
    line-height: 28px;
}
.how-can-we-help-section{
    margin: 50px 0px;
}
.how-can-we-help-section .small-text , .how-can-we-help-section p{
    line-height: 28px;
}
.how-can-we-help-card-container{
    width: 100%;
    display: flex;
    gap: 70px;
    flex-wrap: wrap;
}
.card2{
    margin-top: 20px;
    width: calc((100% / 3) - 70px);
    display: flex;
    flex-direction: column;
    gap: 13px;
    padding-right: 10px;
    transition: padding 0.3s ease, box-shadow 0.3s ease;
}
.card2 .small-text ,.card2 p{
    font-size: 16px;
    line-height: 20px;
}
/* .card2:hover{ */
    /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;} */
.card2-img-div{
    width: 100%;
    height: 200px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
.card2-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.card2 ul{
    padding-left: 13px;
    font-size: 16px;
    line-height: 30px;
    color: #333333;
}
.card2 ul strong{
    display: block;
}
.card2 .card2-content{
    display: flex;
    flex-direction: column;
    gap: 13px;
}
.major-industries-section{
    width: 100%;
    background-color: var(--light-gray);
    padding-top: 30px;
    position: relative;
}
.major-industries-section .small-text , .major-industries-section p{
    line-height: 28px;
}
.arcs-container{
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: left;
    align-items: baseline;
}

/* .major-industries-section svg {
    position: absolute;
    left: 0;
    top: 0;
    transform: scale(0.5);
    transform-origin: top left;
} */

.major-industries-div-container{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    position: relative;
}
.arcLeftSmall{
    position: absolute;
    bottom: 0;
    z-index: 1;
    width: 300px;
}
.arcLeftBig{
    position: absolute;
    bottom: 0;
    z-index: 1;
    width: 600px;
}
.major-industries-div{
    margin-top: 20px;
    width: calc(50% - 30px);
    display: flex;
    gap: 20px;
    z-index: 2;
    padding-bottom: 50px;
}
.major-industries-image-div{
    width: calc(50% - 40px);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    height: 250px;
}
.major-industries-image-div img{
    border-radius: 10px;
    width: 100%;
    height: 100%;
}

.major-industries-description{
    width: 50%;
}
.major-industries-description .small-heading ,.major-industries-description h3{
    font-size: 28px;
}
.major-industries-description .small-text ,.major-industries-description p{
    line-height: 21px;
}
.major-industries-description p{
    color: #333333;
    margin-top: 20px;
}
.read-more-button{
    cursor: pointer;
    color: #333333;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    gap: 15px;
    align-items: center;
    z-index: 2;
}
.read-more-button svg{
    width: 39.08px;
    height: 39.08px;
}

@media screen and (max-width: 1270px){
    .major-industries-div-container{
        gap: 10px;
    }
    .major-industries-div{
        width: calc(50% - 10px);
    }
}
@media screen and (max-width:1150px){
    .major-industries-image-div{
        height: 230px;
    }

}
@media screen and (max-width: 1100px){

    .how-can-we-help-card-container{
        gap: 20px;
    }
    .card2{
        width: calc((100% / 3) - 20px);
    }
    .major-industries-div-container{
        gap: 0px;
        justify-content: space-between;
    }
    .major-industries-div{
        width: calc(50%);
    }
    .who-we-are-section .container{
        flex-direction: column;
        gap: 50px;
    }
    .who-we-are-part-one{
        width: 100%;
    }
    .who-we-are-part-two{
        width: 100%;
    }
    .who-we-are-part-two .corporate-video{
        max-width: 500px;
    }
    .major-industries-image-div{
       width: 52%;
    }
    .major-industries-description{
        width: 48%;
    }
    .major-industries-description .small-heading , .major-industries-description h3{
        font-size: 24px;
    }
    .major-industries-description .small-text ,   .major-industries-description p{
        line-height: 18px;
    }
    .major-industries-description p{
        margin-top: 10px;
    }
    .card2 ul{
        line-height: 24px;
    }
}
@media screen and (max-width:1000px){
    .card2{
        width: calc(50% - 10px);
    }
}
@media screen and (max-width:750px){
    .arc-right-big{
        width: 300px;
    }
    .arc-right-small{
        width: 150px;
    }
    .arcLeftSmall{
        width: 200px;
    }
    .arcLeftBig{
        width: 450px;
    }
}
@media screen and (max-width:700px){
    .major-industries-div-container{
        flex-direction: column;
        justify-content: center;
        padding-left: 10px;
    }
    .major-industries-div{
        width: 90%;
    }
    .major-industries-image-div{
        height: 250px;
    }
    .major-industries-image-div{
        width: 40%;
     }
     .major-industries-description{
         width: 60%;
     }
}
@media screen and (max-width:600px){
    .card2{
        width: 100%;
    }
}

@media screen and (max-width:574px){
    .major-industries-image-div{
        width: 50%;
     }
     .major-industries-description{
         width: 50%;
     }
     .major-industries-image-div{
        height: 200px;
    }
    .major-industries-description .small-heading , .major-industries-description h3{
        font-size: 18px;
    }
    .major-industries-description .small-text ,    .major-industries-description .p{
        line-height: 16px;
    }
    .read-more-button{
        font-size: 14px;
    }
    .read-more-button svg{
        width: 30px;
        height: 30px;
    }
}
@media screen and (max-width:500px){
    .arcLeftSmall{
        display: none;
    }
    .arcLeftBig{
        display: none;
    }
}
@media screen and (max-width:420px){
    .major-industries-div{
        flex-direction: column;
        align-items: center;
    }
    .major-industries-image-div{
        width: 80%;
    }
    .major-industries-description{
        width: 100%;
    }
}


