* {
  -webkit-tap-highlight-color: transparent;
}

*:focus {
  outline: none;
}

.menu_button {
  width: 35px;
  overflow: hidden;
  position: relative;
}

/* Hiding the checkbox */
#menu_checkbox {
  display: none;
}

#menu_label {
  position: relative;
  display: block;
  height: 29px;
  cursor: pointer;
}

#menu_label:before,
#menu_label:after,
.menu_text_bar {
  position: absolute;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: var(--main-color);
}

#menu_label:before,
#menu_label:after {
  content: "";
  transition: 0.4s cubic-bezier(0.68, -0.55, 0.27, 1.55) left;
}

#menu_label:before {
  top: 0;
}

#menu_label:after {
  top: 12px;
}

.menu_text_bar {
  top: 24px;
}

.menu_text_bar:before {
  content: "Close";
  position: absolute;
  top: 5px;
  right: 0;
  left: 0;
  color: var(--main-color);
  font-size: 12px;
  font-weight: bold;
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  text-align: center;
  transition: visibility 0s 0.4s, opacity 0.4s ease-in-out;
}

/* Checkbox checked state */
#menu_checkbox:checked + #menu_label:before {
  left: -39px;
}

#menu_checkbox:checked + #menu_label:after {
  left: 39px;
}

#menu_checkbox:checked + #menu_label .menu_text_bar:before {
  animation: moveUpThenDown 0.8s ease 0.2s forwards,
    shakeWhileMovingUp 0.8s ease 0.2s forwards,
    shakeWhileMovingDown 0.2s ease 0.8s forwards;
}

/* Keyframe animations */
@keyframes moveUpThenDown {
  0% {
    top: 0;
  }
  50% {
    top: -27px;
  }
  100% {
    top: -14px;
  }
}

@keyframes shakeWhileMovingUp {
  0% {
    transform: rotateZ(0);
  }
  25% {
    transform: rotateZ(-10deg);
  }
  50% {
    transform: rotateZ(0deg);
  }
  75% {
    transform: rotateZ(10deg);
  }
  100% {
    transform: rotateZ(0);
  }
}

@keyframes shakeWhileMovingDown {
  0% {
    transform: rotateZ(0);
  }
  80% {
    transform: rotateZ(3deg);
  }
  90% {
    transform: rotateZ(-3deg);
  }
  100% {
    transform: rotateZ(0);
  }
}


@media screen and (max-width: 500px) {
  .menu_button {
    width: 28px; /* Adjust the width */
    height: 20px;
  }

  #menu_label {
    height: 25px; /* Adjust the height of the label */
  }

  #menu_label:before,
  #menu_label:after,
  .menu_text_bar {
    height: 2.5px; /* Reduce the height of the bars */
  }

  #menu_label:before {
    top: 0; /* Adjust position if needed */
  }

  #menu_label:after {
    top: 8px; /* Adjust position if needed */
  }

  .menu_text_bar {
    top: 16px; /* Adjust position if needed */
  }

  #menu_label .menu_text_bar:before {
    font-size: 10px; /* Optionally reduce font size */
  }
}

