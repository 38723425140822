.contact-us-container{
    padding-top: 150px;
}
/* .address-card-container{
    display: flex;
    gap: 50px;
    justify-content: center;
    align-items: center;
    margin-bottom: 250px;
}
.address-card {
    background-color: #f8f9fa;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .address-card .address-icon {
    background-color: var(--main-color);
    padding: 10px;
    border-radius: 50%;
    margin-bottom: 15px;
  }
  
  .address-card .address-icon svg {
    fill: white;
    width: 30px;
    height: 30px;
  }
  
  .address-card .address-details {
    font-size: 16px;
    color: #333;
    line-height: 1.5;
  }
  
  .address-card .address-details p {
    margin: 5px 0;
  }
  
  .address-card .address-details .address-title {
    font-weight: bold;
    margin-bottom: 10px;
  } */
  .address-card-container {
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
    justify-content: center;
    /* align-items: center; */
    margin-bottom: 250px;
  }
  
  .address-card {
    cursor: pointer;
    background-color: #ffffff;
    padding: 25px;
    border-radius: 15px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
    width: 350px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .address-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  }
  
  .address-card .address-icon {
    background-color: #293f8e;
    padding: 15px;
    border-radius: 50%;
    margin-bottom: 10px;
  }
  
  .address-card .address-icon svg {
    fill: white;
    width: 35px;
    height: 35px;
  }
  
  .address-card .address-details {
    font-size: 17px;
    color: #555555;
    line-height: 1.6;
  }
  
  .address-card .address-details p {
    margin: 8px 0;
  }
  
  .address-card .address-details .address-title {
    font-weight: bold;
    color: #293f8e;
    margin-bottom: 12px;
    font-size: 18px;
  }
  
  /* Adding a subtle border at the top of address details */
  .address-card .address-details::before {
    content: "";
    display: block;
    width: 60px;
    height: 3px;
    background-color: #293f8e;
    margin: 0 auto 12px auto;
  }

  @media screen and (max-width:900px) {
    .address-card-container{
        margin-bottom: 50px;
    }
  }
  @media screen and (max-width:380px){
    .address-card{
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
  }
  