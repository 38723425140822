.industries-first-div{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 60dvh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.industries-first-div .large-heading{
    color: white;
    margin-top: 100px;
}
.industries-content-card-container{
    padding-top: 80px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 40px;
    margin-left: auto;
    margin-right: auto;
}
.industries-content-card{
    width: 260px;
    height: 75px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 5px;
    padding: 2px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
.industries-content-card .card-content{
    width: 100%;
    height: 100%;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 1px solid transparent;
    transition: border 0.5s ease;
}
.industries-content-card .card-content:hover{
    border: 1px solid white;
}
.industries-content-card .card-content.active{
    border: 1px solid white;
}
.industries-content-card .card-heading{
    font-size: 22px;
    font-weight: 600;
    color: white;
    text-transform: capitalize;
}
.read-more-industrie-card{
    font-size: 11px;
    font-weight: 400;
    color: white;
    display: flex;
    align-items: center;
    gap: 8px;
}
.read-more-industrie-card svg{
    fill: white;
    width: 25px;
}
.industries-selected-content{
    padding: 50px;
}
.industries-selected-content .large-heading{
    text-transform: capitalize;
    margin-bottom: 20px;
}
.industries-selected-content .sub-large-heading , .industries-selected-content h2{
    margin-bottom: 8px;
}
.industries-selected-content-table{
    width: 100%;
    justify-content: space-between;
    display: flex;
    flex-wrap: wrap;
    background-color: var(--light-gray);
    border-radius: 10px;
    padding: 50px;
    margin-bottom: 50px;
}
.content-table-item{
    width: calc((90%  - 50px) / 4);
    display: flex;
    flex-direction: column;
    gap: 25px;
}
.content-table-item .content-heading{
    font-size: 14px;
    font-weight: 600;
}
.content-table-item .content-description{
    font-size: 11px;
}
.content-table-item .content-description p{
    font-size: 11px;
    line-height: 20px;
}
.industries-content p{
    overflow-wrap: break-word;
    hyphens: auto;              
    word-break: keep-all;
    text-align: justify;
    margin-bottom: 10px;
}

@media screen and (max-width: 1270px){
    .companys-list{
        padding: 30px 20px;
    }
    .industries-selected-content-table{
        gap: 20px;
    }
}

@media screen and (max-width: 1130px){
    .industries-content-card{
        margin-right: 10px;
        margin-top: 0px;
    }
}

@media screen and (max-width:700px){
    .content-table-item{
        width: calc(50% -  20px);
        gap: 10px;
    }
}
@media screen and (max-width:500px){
    .content-table-item{
        width: 100%;
    }
}