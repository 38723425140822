
.card-group-div{
    width: 100%;
    overflow: hidden;

}
.card-groups{
    display: flex;
    /* transition: transform 1s ease-in; */
    will-change: transform;
}
.solution-card{
    position: relative;
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: white;
    width: 350px;
    height: 430px;
    gap: 20px;
    padding: 50px 40px;
    position: relative;
    transition: width 0.3s ease;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.solution-card:hover{
    width: 600px;
}
.solution-card-heading{
    width: 100%;
}
.solution-card-heading .card-heading{
    font-size: 18px;
    font-weight: bold;
    text-transform: capitalize;
}
.card-description{
    font-size: 13px;
    line-height: 28px;
}
.card-points{
    padding: 5px 20px;
    font-size: 12px;
}
.card-points-item{
    margin-bottom: 10px;
}
.card-footer {
    position: absolute;
    bottom: 20px;
    display: flex;
    align-items: center;
    margin-top: auto;
  }
 .solution-card-content{
    overflow-y: hidden;
    margin-bottom: 30px;
 } 
.navigation-bar-cards{
    height: 75px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: #F5F5F5;
    padding:0px 20px;
}
.navigation-bar-cards svg {
    width: 35px;
    height: 35px;
    cursor: pointer;
    transition: fill 0.3s ease, transform 0.3s ease;
  }
  .navigation-bar-cards svg path {
    stroke-width: 6;
    stroke: var(--main-color);
  }
  
  .navigation-bar-cards svg:hover {
    transform: scale(1.1);
  }
  @media screen and (max-width:700px){
    .navigation-bar-cards{
        height: 50px;
    }
    .navigation-bar-cards svg{
        width: 25px;
    }
  }
  @media screen and (max-width:600px){
    .solution-card:hover{
        width: 100vw;
    }
  }
  @media screen and (max-width:500px){
    .solution-card{
        width: 100vw;
    }
  }