.carousel-container{
  position: relative;
    display: flex;
    align-items: center;
    height: 102dvh;
    width: 100%;
    transition: background-image 0.8s ease-in-out;
    /* clip-path: polygon(0 0, 100% 0, 100% 60%, 0 85%); */

}
.carousel-container .large-heading{
  transition: opacity 0.5s ease-in-out;
  color: white;
}
.fade-in {
  opacity: 1;
}

.fade-out {
  opacity: 0;
}
.carousel-container .descriptions {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.descriptions.fade-in {
  opacity: 1; /* Fully visible */
}

.descriptions.fade-out {
  opacity: 0; /* Fully hidden */
}
.carousel-container .container{
    padding: 10px 70px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    z-index: 201;
}
.carousel-indicators {
    padding: 0px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    justify-content: space-around;
  }
  
  .dot {
    margin-left: 10px;
    width: 8px;
    height: 8px;
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid white;
    cursor: pointer;
    transition: background-color 0.3s;
    z-index: 12;
    transition: background-color 0.3s, transform 0.3s ease-in-out; 
  }
  
  .dot.active {
    background-color: var(--main-color);
    transform: scale(1.5);
  }
  .dot:hover {
    transform: scale(1.2);
  }
  .scroll-down {
    position: absolute;
    bottom: 70px;
    left: 60px;
    color: white;
    cursor: pointer;
    animation: bounce 2s infinite;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
    z-index: 1;
  }
  .scroll-down a{
    text-decoration: none;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .scroll-down span{
    font-size: 14px;
    font-weight: bold;
  }
  .scroll-down svg{
    width: 34px;
  }

  #carousal-svg{
    width: 100%;
    position: absolute;
    /* z-index: 200; */
    left: 0;
    right: 0;
    bottom: 0;
  }
  @media screen and (max-width:1000px){
    .carousel-container{
      height: 98dvh;
    }
  }
  @media screen and (max-width:800px){
    .scroll-down{
      left: 30px;
      bottom: 60px;
    }
    .scroll-down p{
      font-size: 12px;
    }
    .scroll-down svg{
      width: 32px;
    }
  }
  @media screen and (max-width:750px){
    .carousel-container .container{
      padding: 5px 40px;
    }
  }
  @media screen  and (max-width:690px){
    .carousel-container .container{
      padding: 5px 20px;
      gap: 10px;
    }
  }
  @media screen and (max-width:650px){
    .carousel-indicators{
      gap: 5px;
      padding: 0px 10px;
    }
    .dot{
      margin-left: 6px;
      width: 5px;
      height: 5px;
    }
  }