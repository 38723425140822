body{
  background-color: #ffff;
}
.container {
  width: 100%;
  max-width: 1300px;
  padding: 0 70px;
  margin: 0 auto;
  box-sizing: border-box;
}

@media screen and (max-width: 1400px) {
  .container {
      max-width: 1320px;
  }
}

@media screen and (max-width: 1200px) {
  .container {
      max-width: 1140px;
  }
}

@media screen and (max-width: 992px) {
  .container {
      max-width: 960px;
      padding: 0 40px;
  }
}

@media screen and (max-width: 962px) {
  .container {
      max-width: 950px;
  }
}
@media screen and (max-width: 768px) {
  .container {
      max-width: 720px;
      padding: 0 10px;
  }
}

@media screen and (max-width: 576px) {
  .container {
      max-width: 540px;
      padding: 0 10px;
  }
}


/* App.css */

.page {
  opacity: 0;
  transform: translateX(-100px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.page-active {
  opacity: 1;
  transform: translateX(0);
}
