.footer-container{
    background-color: #F5F5F5;
}

.credits-div{
    width: 100%;
    height: 50px;
    display: flex;
    background-color: var(--main-color);
    color: white;
    font-size: 14px;
    align-items: center;
}
.credits-div .container{
    display: flex;
    justify-content: center;
}
.credits-part-one{
    display: flex;
    gap: 30px;
}
.credits-div p{
    color: white;
    transition: color 0.3s ease, letter-spacing 0.3s ease;
}
.credits-div p:hover{
    cursor: pointer;
    color: whitesmoke;
    letter-spacing: 1.5px;
}

.sub-footer-div{
    width: 100%;
    background-color: var(--light-gray);
}
.sub-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 100px;
    gap: 150px;
  }
  
  .footer-logo img {
    width: 130px;
  }
  
  .footer-links {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    color: #3D3D3D;
  }

  .solution-footer .footer-columns{
    display: flex;
    flex-wrap: wrap;
    /* justify-content: center; */
    gap: 60px;
  }
  .footer-column{
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
  }
  .footer-column .footer-column-heading {
    font-size: 24px;
    font-weight: bold;
  }
  
  .footer-column ul {
    list-style: none;
    padding: 0;
  }
  .footer-column li{
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 12px;
    font-weight: 600;
    font-size: 12px;
  }
  .footer-column li::before{
    content: '>';
    position: absolute;
    left: 0;
    color: var(--main-color);
  }
  .footer-column ul li a {
    color: #666;
    text-decoration: none;
    line-height: 1.8;
  }
  .footer-column ul li a:hover{
    color: var(--main-color);
  }
  .social-icons{
    display: flex;
    align-items: center;
  }
  .social-icons a svg {
    fill: var(--main-color);
    color: var(--main-color);
    width: 16px;
    margin-right: 15px;
    transition: transform 0.3s ease;
  }
  .social-icons a svg:hover{
    cursor: pointer;
    transform: scale(1.2);
    transition: fill 0.3s ease, transform 0.3s ease;
  }
  .arrow-up-navigation-div{
    width: 100%;
    display: flex;
    justify-content: end;
    padding: 30px;
  }
  .arrow-up-navigation-div svg{
    cursor: pointer;
    width: 30px;
    fill: #2A4091;
  }
  .arrow-up-navigation-div svg:hover{
    transform: scale(1.1);
    transition: transform 0.3s ease-in-out;
  }

/* media */


@media screen and  (max-width: 1200px){
  .contact-support-section{
    width: 35%;
  }
}
@media screen and (max-width: 1130px){
  .sub-footer{
      gap: 50px;
  }
}

@media screen and (max-width:1080px){
  .sub-footer-div .container{
    width: 100%;
    padding: 0px 20px;
  }
}
@media screen and (max-width:950px){
  .sub-footer{
    flex-direction: column;
    padding-top: 20px;
    gap: 30px;
  }
}
@media screen and (max-width:900px){
  .footer-column{
    gap: 10px;
  }
  .footer-column .footer-column-heading{
    font-size: 18px;
  }

}
/* @media screen and (max-width:700px){
  .company-scroll img{
    width: 120px;
    height: 60px;
  }
} */
  @media screen and (max-width:600px){
    .footer-links{
      width: auto;
      flex-direction: column;
      align-items: flex-start;
      justify-content: left;
    }
    .footer-column{
      margin-left: 1px;
      margin-right: 1px;
    }
  }
  @media screen and (max-width:550px){
    .credits-div{
      font-size: 12px;
    }
  }
  @media screen and (max-width:510px){
    .solution-footer .footer-columns{
      gap: 0px;
      flex-direction: column;
    }
  }
  @media screen and (max-width:450px){
    .credits-div {
      padding: 30px;
    }
    .credits-div .container{
     flex-direction: column;
     gap: 5px;
     align-items: center;
    }
  }

  
  @keyframes scroll {
    to {
      transform: translate(calc(-50% - 25px));
    }
  }



/* #F5F5F5 */